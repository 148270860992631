<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin log'">
          <template v-slot:preview>
            <h5 class="bold-text">Thông tin log</h5>
            <hr />

            <b-container class="bv-example-row">
              <b-row class="justify-content-md-center mb-10">
                <b-col>
                  <b-row>
                    <b-col class="col-md-3">
                      <p class="header-color">Loại log:</p>
                      <p class="header-color">Mã:</p>
                    </b-col>
                    <b-col>
                      <p>{{ type }}</p>
                      <p>{{ id }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="col-md-3">
                      <p class="header-color">Người sửa:</p>
                      <p class="header-color">IP:</p>
                    </b-col>
                    <b-col>
                      <p>
                        <b>{{ updatedBy }}</b>
                      </p>
                      <p>{{ clientIp }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="col-md-3">
                      <p class="header-color">Cửa hàng:</p>
                    </b-col>
                    <b-col>
                      <p>{{ storeName }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>

            <h5 class="bold-text">Thông tin thay đổi</h5>
            <hr />
            <b-container class="bv-example-row">
              <table
                border="1"
                style="width: 60%"
                class="
                  table table-bordered table-vertical-center table-hover
                  mb-10
                "
              >
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                </colgroup>
                <thead class="bg-secondary">
                  <tr>
                    <th rowspan="2">Thông tin trên phiếu</th>
                  </tr>
                  <tr>
                    <th>Trước khi sửa</th>
                    <th>Sau khi sửa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isModified(diffInfo._.customerNote)">
                    <th class="header-color">Ghi chú khách hàng</th>
                    <td>
                      {{
                        diffInfo._.customerNote
                          ? diffInfo._.customerNote.original
                          : ''
                      }}
                    </td>
                    <td>
                      {{
                        diffInfo._.customerNote
                          ? diffInfo._.customerNote.current
                          : ''
                      }}
                    </td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.customerNote)">
                    <th class="header-color">Ghi chú khách hàng</th>
                    <td></td>
                    <td>
                      {{
                        diffInfo._.customerNote
                          ? diffInfo._.customerNote.current
                          : ''
                      }}
                    </td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.cashAccountId.status)">
                    <th class="header-color">Tài khoản thanh toán</th>
                    <td>{{ diffInfo._.cashAccountId.original }}</td>
                    <td>{{ diffInfo._.cashAccountId.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.cashAccountId.status)">
                    <th class="header-color">Tài khoản thanh toán</th>
                    <td></td>
                    <td>{{ diffInfo._.cashAccountId.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.checkDate.status)">
                    <th class="header-color">Ngày đến cửa hàng</th>
                    <td>{{ diffInfo._.checkDate.original }}</td>
                    <td>{{ diffInfo._.checkDate.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.checkDate.status)">
                    <th class="header-color">Ngày đến cửa hàng</th>
                    <td></td>
                    <td>{{ diffInfo._.cashAccountId.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.checkTime.status)">
                    <th class="header-color">Giờ đến cửa hàng</th>
                    <td>{{ diffInfo._.checkTime.original }}</td>
                    <td>{{ diffInfo._.checkTime.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.checkTime.status)">
                    <th class="header-color">Giờ đến cửa hàng</th>
                    <td></td>
                    <td>{{ diffInfo._.checkTime.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.codeShip.status)">
                    <th class="header-color">Mã vận đơn</th>
                    <td>{{ diffInfo._.codeShip.original }}</td>
                    <td>{{ diffInfo._.codeShip.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.codeShip.status)">
                    <th class="header-color">Mã vận đơng</th>
                    <td></td>
                    <td>{{ diffInfo._.codeShip.current }}</td>
                  </tr>
                  <tr v-show="isModified(diffInfo._.customerShipFee.status)">
                    <th class="header-color">Phí vận chuyển thu của khách</th>
                    <td>{{ diffInfo._.customerShipFee.original }}</td>
                    <td>{{ diffInfo._.customerShipFee.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.customerShipFee.status)">
                    <th class="header-color">Phí vận chuyển thu của khách</th>
                    <td></td>
                    <td>{{ diffInfo._.customerShipFee.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.depositAmount.status)">
                    <th class="header-color">Tiền đặt cọc</th>
                    <td>{{ diffInfo._.depositAmount.original }}</td>
                    <td>{{ diffInfo._.depositAmount.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.depositAmount.status)">
                    <th class="header-color">Tiền đặt cọc</th>
                    <td></td>
                    <td>{{ diffInfo._.customerShipFee.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.discountAmount.status)">
                    <th class="header-color">Chiết khấu</th>
                    <td>{{ diffInfo._.discountAmount.original }}</td>
                    <td>{{ diffInfo._.discountAmount.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.discountAmount.status)">
                    <th class="header-color">Chiết khấu</th>
                    <td></td>
                    <td>{{ diffInfo._.discountAmount.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.discountType.status)">
                    <th class="header-color">Kiểu chiết khấu</th>
                    <td>{{ diffInfo._.discountType.original }}</td>
                    <td>{{ diffInfo._.discountType.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.discountType.status)">
                    <th class="header-color">Kiểu chiết khấu</th>
                    <td></td>
                    <td>{{ diffInfo._.discountType.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.orderSourceId.status)">
                    <th class="header-color">Nguồn đơn hàng</th>
                    <td>{{ diffInfo._.orderSourceId.original }}</td>
                    <td>{{ diffInfo._.orderSourceId.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.orderSourceId.status)">
                    <th class="header-color">Nguồn đơn hàng</th>
                    <td></td>
                    <td>{{ diffInfo._.orderSourceId.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.orderType.status)">
                    <th class="header-color">Loại đơn</th>
                    <td>{{ diffInfo._.orderType.original }}</td>
                    <td>{{ diffInfo._.orderType.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.orderType.status)">
                    <th class="header-color">Loại đơn</th>
                    <td></td>
                    <td>{{ diffInfo._.orderType.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.paymentDate.status)">
                    <th class="header-color">Ngày hẹn thanh toán</th>
                    <td>{{ diffInfo._.paymentDate.original }}</td>
                    <td>{{ diffInfo._.paymentDate.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.paymentDate.status)">
                    <th class="header-color">Ngày hẹn thanh toán</th>
                    <td></td>
                    <td>{{ diffInfo._.paymentDate.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.saleNote.status)">
                    <th class="header-color">Ghi chú CSKH</th>
                    <td>{{ diffInfo._.saleNote.original }}</td>
                    <td>{{ diffInfo._.saleNote.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.saleNote.status)">
                    <th class="header-color">Ghi chú CSKH</th>
                    <td></td>
                    <td>{{ diffInfo._.saleNote.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.shipFee.status)">
                    <th class="header-color">Phí trả HVC</th>
                    <td>{{ diffInfo._.shipFee.original }}</td>
                    <td>{{ diffInfo._.shipFee.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.shipFee.status)">
                    <th class="header-color">Phí trả HVC</th>
                    <td></td>
                    <td>{{ diffInfo._.shipFee.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.status.status)">
                    <th class="header-color">Trạng thái đơn</th>
                    <td>{{ diffInfo._.statusName.original }}</td>
                    <td>{{ diffInfo._.statusName.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.status.status)">
                    <th class="header-color">Trạng thái đơn</th>
                    <td></td>
                    <td>{{ diffInfo._.status.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.storeId.status)">
                    <th class="header-color">Cửa hàng</th>
                    <td>{{ diffInfo._.storeId.original }}</td>
                    <td>{{ diffInfo._.storeId.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.storeId.status)">
                    <th class="header-color">Cửa hàng</th>
                    <td></td>
                    <td>{{ diffInfo._.storeId.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.transferAccountId.status)">
                    <th class="header-color">Tài khoản chuyển khoản</th>
                    <td>{{ diffInfo._.transferAccountId.original }}</td>
                    <td>{{ diffInfo._.transferAccountId.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.transferAccountId.status)">
                    <th class="header-color">Tài khoản chuyển khoản</th>
                    <td></td>
                    <td>{{ diffInfo._.transferAccountId.current }}</td>
                  </tr>
                  <tr v-if="isModified(diffInfo._.transferAmount.status)">
                    <th class="header-color">Tiền chuyển khoản</th>
                    <td>{{ diffInfo._.transferAmount.original }}</td>
                    <td>{{ diffInfo._.transferAmount.current }}</td>
                  </tr>
                  <tr v-if="isAdded(diffInfo._.transferAmount.status)">
                    <th class="header-color">Tiền chuyển khoản</th>
                    <td></td>
                    <td>{{ diffInfo._.transferAmount.current }}</td>
                  </tr>
                </tbody>
              </table>
            </b-container>
            <h5 class="bold-text">Chi tiết thay đổi sản phẩm</h5>
            <hr />
            <b-container class="bv-example-row">
              <table
                class="
                  table table-bordered table-vertical-center table-hover
                  mb-10
                "
              >
                <tr>
                  <th style="width: 50%">Trước</th>
                  <th>Sau</th>
                </tr>
                <tr v-for="(item, index) in diffItem._" :key="index">
                  <td>
                    <div
                      v-if="item.status === 'ADDED'"
                      v-show="item.status === 'ADDED'"
                    >
                      <span>{{ item.original }}</span>
                    </div>

                    <div>
                      <span
                        v-if="item.status === 'MODIFIED'"
                        v-show="item.status === 'MODIFIED'"
                      >
                        <p class="text-primary header-color">
                          {{ item._.productName.original }}
                        </p>
                        <b-row>
                          <b-col class="col col-sm-3">
                            <p class="header-color">ID sản phẩm:</p>
                            <p class="header-color">Imei:</p>
                            <p class="header-color">Giá:</p>
                            <p class="header-color">Số lượng:</p>
                            <p class="header-color">Chiết khấu:</p>
                          </b-col>
                          <b-col>
                            <p>{{ item._.productId.original || 0 }}</p>
                            <p>
                              {{
                                item._.imeiCode.original !== ''
                                  ? item._.imeiCode.current
                                  : 0
                              }}
                            </p>
                            <p>
                              {{ convertPrice(item._.productPrice.original) }}
                            </p>
                            <p>{{ item._.quantity.original }}</p>
                            <p>{{ item._.discountAmount.original }}</p>
                          </b-col>
                        </b-row>
                      </span>
                    </div>
                  </td>

                  <td>
                    <div
                      v-if="item.status === 'ADDED'"
                      v-show="item.status === 'ADDED'"
                    >
                      <p class="text-primary header-color">
                        {{ item.current.productName }}
                      </p>
                      <b-row>
                        <b-col class="col col-sm-3">
                          <p class="header-color">ID sản phẩm:</p>
                          <p class="header-color">Imei:</p>
                          <p class="header-color">Giá:</p>
                          <p class="header-color">Số lượng:</p>
                          <p class="header-color">Chiết khấu:</p>
                        </b-col>
                        <b-col>
                          <p>{{ item.current.productId }}</p>
                          <p>
                            {{
                              item.current.imeiCode !== ''
                                ? item.current.imeiCode
                                : 0
                            }}
                          </p>
                          <p>{{ convertPrice(item.current.productPrice) }}</p>
                          <p>{{ item.current.quantity }}</p>
                          <p>{{ item.current.discountAmount }}</p>
                        </b-col>
                      </b-row>
                    </div>

                    <div>
                      <span
                        v-if="item.status === 'MODIFIED'"
                        v-show="
                          item.status === 'MODIFIED' &&
                          item._.deleted.status === 'EQUAL'
                        "
                      >
                        <p class="text-primary header-color">
                          {{ item._.productName.current }}
                        </p>
                        <b-row>
                          <b-col class="col col-sm-3">
                            <p class="header-color">ID sản phẩm:</p>
                            <p class="header-color">Imei:</p>
                            <p class="header-color">Giá:</p>
                            <p class="header-color">Số lượng:</p>
                            <p class="header-color">Chiết khấu:</p>
                          </b-col>
                          <b-col>
                            <p>{{ item._.productId.current }}</p>
                            <p>{{ item._.imeiCode.current || 0 }}</p>
                            <p>
                              {{ convertPrice(item._.productPrice.current) }}
                            </p>
                            <p>{{ item._.quantity.current }}</p>
                            <p>{{ item._.discountAmount.current }}</p>
                          </b-col>
                        </b-row>
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
            </b-container>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <router-link to="/order-history" tag="button">
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <!-- </b-col> -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      id: '',
      type: '',
      updatedBy: '',
      storeName: '',
      diffInfo: {},
      diffItem: {},
      clientIp: '',
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lịch sử đơn hàng', route: '/order-history' },
      { title: 'Chi tiết lịch sử đơn hàng', route: '/detail-order-history' },
    ]);

    this.id = this.$route.query.id;
  },
  created() {
    this.getDetailHistory();
  },
  methods: {
    convertPrice: function (number) {
      let result = new Intl.NumberFormat('vn-VN').format(number);
      return isNaN(parseFloat(result)) ? '' : result;
    },
    setCurrentAddedObj(obj) {
      let result = JSON.parse(obj);
      return result;
    },
    isModified(value) {
      return value === 'MODIFIED';
    },
    isAdded(value) {
      return value === 'ADDED';
    },
    getHistoryType(typeId) {
      switch (typeId) {
        case 1:
          return 'Đơn hàng';
        case 2:
          return 'Hóa đơn';
        default:
          return '';
      }
    },
    getDetailHistory: async function () {
      let params = {
        //  loại đơn hàng
        type: 1,
      };
      await ApiService.setHeader();
      await ApiService.query('/histories/' + this.id, { params }).then(
        (res) => {
          let { type, createdByName, storeName, diffInfo, diffItem, clientIp } =
            res.data.data;
          this.type = this.getHistoryType(type);
          this.updatedBy = createdByName;
          this.storeName = storeName;
          this.clientIp = clientIp;
          this.diffInfo = diffInfo;
          this.diffItem = diffItem;
        }
      );
    },
  },
};
</script>

<style>
.header-color {
  font-weight: 500;
}

.bold-text {
  color: #212121;
}
</style>
